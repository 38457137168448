import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../layouts/base.css"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const posts = edges
  console.log(posts)
  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <div className="blog-posts wrapperBlog">
        {posts
          .filter(post => !!post.node.frontmatter.date) // You can filter your posts based on some criteria
          .map(post => (
            <a href={post.node.frontmatter.link} target="_blank">
              <div className="blog-post-preview" key={post.node.id}>
                <div className="wrapperImage">
                  <img
                    width="100%"
                    height="100px"
                    src={post.node.frontmatter.image}
                    alt={post.node.frontmatter.image}
                  />
                </div>
                <div className="date">{post.node.frontmatter.date}</div>
                <div className="title">{post.node.frontmatter.title}</div>
                <p className="excerpt">{post.node.excerpt}</p>
              </div>
            </a>
          ))}
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fileAbsolutePath: { regex: "/(/postsRecommendedContent)/.*.md$/" }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          excerpt(pruneLength: 200)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            image
            link
          }
        }
      }
    }
  }
`
